import * as Msdyn365 from '@msdyn365-commerce/core';
import {
    generateCacheKey
} from '@msdyn365-commerce-modules/retail-actions';
import { IClearanceProductSearchResult, IClearanceSearchCriteria } from '../../../actions/DataServiceEntities.g';
import { searchAvailableClearanceLinesAsync } from '../../../actions/DataActionExtension.g';
import { QueryResultSettings } from '@msdyn365-commerce/retail-proxy';

/**
 * GetClearanceLines Input Action
 */
export class SearchClearanceLinesInput implements Msdyn365.IActionInput {
    public searchCriteria: IClearanceSearchCriteria;
    public apiSettings: Msdyn365.ICommerceApiSettings;
    public queryResultSettings?: QueryResultSettings;

    constructor(
        searchCriteria: IClearanceSearchCriteria,
        apiSettings: Msdyn365.ICommerceApiSettings,
        queryResultSettings: QueryResultSettings
    ) {
            this.searchCriteria = searchCriteria;
            this.apiSettings = apiSettings;
            this.queryResultSettings = queryResultSettings;
    }

    public getCacheKey = (): string =>
        generateCacheKey(
            this.apiSettings,
            { key: 'ProductIds', value: this.searchCriteria.ProductIds?.sort((id1, id2) => (id1 > id2 ? -1 : 1)).join(',') },
            { key: 'ItemIds', value: this.searchCriteria.ItemIds?.sort((id1, id2) => (id1 > id2 ? -1 : 1)).join(',') },
            { key: 'LocationIds', value: this.searchCriteria.LocationIds?.sort((id1, id2) => (id1 > id2 ? -1 : 1)).join(',') },
            { key: 'Skip', value: this.queryResultSettings?.Paging?.Skip },
            { key: 'Top', value: this.queryResultSettings?.Paging?.Top }
        );
    public getCacheObjectType = () => 'ProductSearchResult';
    public dataCacheType = (): Msdyn365.CacheType => 'request';
}

export const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    return new SearchClearanceLinesInput({}, args.requestContext.apiSettings, {});
};

export async function searchAvailableClearanceLines(input: SearchClearanceLinesInput, ctx: Msdyn365.IActionContext): Promise<IClearanceProductSearchResult> {
    let paging = input.queryResultSettings?.Paging;
    return searchAvailableClearanceLinesAsync({ callerContext: ctx }, input.searchCriteria, paging?.Skip ?? 0, paging?.Top ?? 10);
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IClearanceProductSearchResult>>searchAvailableClearanceLines,
    id: 'GetClearanceLines',
    input: createInput
});
